$primary: #0d2553;
$secondary: #2196f3;
$success: #4caf50;
$info: #2196f3;
$warning: #fb8c00;
$danger: #ff5252;

@import "~bootstrap/scss/bootstrap";
@import "~formiojs/dist/formio.full.min.css";

// .formio-component {
//   &.formio-component-button {
//     &.formio-component-submit {
//       button {
//         i {
//           display: none;
//         }
//       }
//     }
//   }
// }
