.blur-gradient {
  background: linear-gradient(
    102.93deg,
    #e6e6e6 1.28%,
    rgba(255, 255, 255, 0) 98.56%
  );
}

.round-border {
  border-radius: 11px;
}

.gray-ccc {
  color: #cccccc;
}

.bg-gray {
  background-color: #c4c4c4;
}

/* Blurred glass backgrounds */
.blurred-glass {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}
